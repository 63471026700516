<template>
    <div class="box">
        <h2>Crefo Prüfungen zuweisen</h2>
        <div class="body">
            <table>
                <tbody>
                    <tr class="clickable-row" v-for="assignment in crefoAssignments" v-bind:key="assignment.id">
                        <td @click="load(assignment.id)">{{ assignment.label }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            crefoAssignments: {
                total: 0,
                results: []
            }
        };
    },
    created() {
        this.fetchData();
    },
    methods: {
        load(key) {
            this.$router.push({ name: "crefo_assignment_edit", params: { id: key } });
        },
        fetchData() {
            this.$api.get("/crefo/assignments").then(
                response => {
                    this.crefoAssignments = response.data.data;
                },
                () => {
                    this.$snotify.error("Fehler beim Laden der Zuordnungen");
                }
            );
        }
    }
};
</script>
